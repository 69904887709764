import "core-js/modules/es.array.push.js";
import strings from '@/utils/strings.js'; //封装的js方法

import store from '@/store/index.js';
export default {
  name: 'info',
  components: {
    strings
  },
  data() {
    return {
      currentRate: 0,
      text1: '加载中',
      text2: '',
      msg: '跳转中......',
      query: {},
      editcphm: false,
      loading: false,
      showdownload: false,
      fxt: '',
      pageinfo: {
        size: 10,
        page: 1
      },
      host: '',
      isfinish: false,
      users: [],
      users2: [],
      lengthCP: 7,
      aid: -1,
      activeInfo: {},
      spread: {
        uid: 0
      },
      fieldValue: '',
      show: false,
      showKey: false,
      show_chinese: false,
      show_allBoard: false,
      html: "六项服务兑换卡才<em style='color:red;font-size:24px'>99.8</em>抢购<br/>还有抽纸巾·青花瓷餐具亲友一起免费享受",
      shedules: [],
      formatter: null,
      errorImg: 'this.src="' + require('@/assets/logo.png') + '"',
      isNew: false,
      footerButtons: [{
        event: 'click1',
        clickName: '操作一'
      },
      // event的值 对应组件的点击事件 clickName为按钮显示的文案
      {
        event: 'click2',
        clickName: '操作二'
      }],
      headers: {
        Authorization: ''
      },
      importHeaders: {
        token: ''
      },
      UserInfo: {},
      formdata: {
        telephone: '',
        vehicleType: '',
        licensePlate: '',
        activityId: '',
        spreadId: ''
      },
      orders: 0,
      cphm: '',
      info: {},
      pswd: {
        oldPswd: '',
        newPswd: '',
        repeatPswd: ''
      },
      listData1: new Array(5),
      rules: {
        oldPswd: [{
          required: true,
          message: '旧密码不能为空',
          trigger: 'blur'
        }],
        newPswd: [{
          required: true,
          message: '新密码不能为空',
          trigger: 'blur'
        }],
        repeatPswd: [{
          required: true,
          message: '重复密码不能为空',
          trigger: 'blur'
        }]
      },
      newNumberFlag: false,
      plate_number: '',
      //车牌号
      first: ' ',
      id: 6,
      animate: false,
      setInTime: '',
      // 定时器
      shareCfg: {} // 定时器
    };
  },

  methods: {
    getWxOauth2() {
      var aid = this.$route.query.aid;
      var spread = this.$route.query.spread;
      var redirect = this.$route.query.redirect;
      var admin = this.$route.query.admin;
      var host = location.protocol + '//' + location.host;
      console.log(redirect);
      if (!redirect) {
        redirect = '';
      }
      if (!aid) {
        aid = 1;
      }
      if (!spread) {
        spread = 1;
      }
      localStorage.setItem('host', host);
      var redirectUrl = host + '/auth?aid=' + aid + '&spread=' + spread + '&admin=' + admin + '&redirect=' + redirect;
      console.log(encodeURIComponent(redirectUrl));
      window.location.href = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxb948ea0dd34db6b6&' + 'redirect_uri=' + encodeURIComponent(redirectUrl) + '&' + 'response_type=code&scope=snsapi_userinfo&state=state#wechat_redirect';
    },
    goMycards() {
      this.$router.push('/myinfo');
    },
    // 第二种方式直接下载图片
    download() {
      console.log('download');
      this.$data.showdownload = true;
      var that = this;
      this.$toast.loading({
        duration: 0,
        forbidClick: true,
        message: '服务君正在努力加载'
      });
      setTimeout(() => {
        const ref = this.$refs.content; // 截图区域
        html2canvas(ref, {
          backgroundColor: '#e8f4ff',
          useCORS: true // 如果截图的内容里有图片,可能会有跨域的情况,加上这个参数,解决文件跨域问题
        }).then(canvas => {
          that.showdownload = false;
          const dataURL = canvas.toDataURL('image/png');
          this.$data.fxt = dataURL;
          ImagePreview({
            images: [dataURL],
            closeable: true,
            onClose() {}
          });
          that.$toast.clear(); // 关闭加载
        });
      }, 2000);
    },
    call(obj) {
      var mapUrl_tx = 'tel:' + obj;
      window.location.href = mapUrl_tx;
    },
    loadmore() {
      var that = this;
      var aid = that.query.aid;
      proxy.$http.get('/api/orderInfo/getOrderListPage/' + aid + '/' + this.pageinfo.page + '/' + this.pageinfo.size, null, 'Loding....').then(result => {
        console.log(result);
        if (result.success) {
          result.data.list.forEach(element => {
            element.id = that.$data.users.length + 1;
            that.$data.users.push(element);
          });
          if (!result.data.nextPage) {
            that.$data.isfinish = true;
          }
          that.pageinfo.page++;
        } else {
          this.$toast.fail(result.msg);
        }
      });
    },
    // 滚动栏滚动
    showMarquee() {
      this.animate = true;
      setTimeout(() => {
        this.users2.push(this.users2[0]);
        this.users2.shift();
        this.animate = false;
      }, 500);
    },
    signup() {
      window.location.href = '/signup';
    },
    onFinish(val) {
      console.log(val);
      this.$data.show = false;
    },
    cancel() {
      console.log('cancel');
      this.editcphm = false;
    },
    confirm(aaa) {
      console.log('confirm');
      console.log(aaa);
      this.$data.formdata.licensePlate = aaa;
      this.$data.editcphm = false;
    },
    onclick() {
      this.$data.show = true;
    },
    edit() {
      if (this.cphm.length < 1) {
        this.$data.show_chinese = true;
      } else {
        this.$data.show_allBoard = true;
      }
    },
    auth() {
      console.log('....................................');
      var that = this;
      var query = this.$route.query;
      query.spread = 1;
      var redirect = '';
      var code = query.code;
      var state = query.state;
      if (true) {
        that.$http.get('/api/wechat/auth', query, null).then(result => {
          localStorage.setItem('code', code);
          console.log(result);
          if (result.success) {
            // that.$toast("登陆成功");
            var newkey = {};
            newkey.aid = query.aid;
            newkey.spread = query.spread;
            localStorage.setItem('query', JSON.stringify(newkey));
            store.commit('setUserInfo', result.data);
            that.UserInfo = JSON.parse(localStorage.user).userInfo;
            var role = JSON.parse(localStorage.user).role;
            // if (result.data.user.realName.length == 18) {
            //   window.history.replaceState(null, "", "/index");
            //   window.history.go(0);
            // } else {
            if (state.indexOf('/') > 0) {
              window.history.replaceState(null, '', state);
            } else {
              window.history.replaceState(null, '', '/ycf/index');
            }
            window.history.go(0);
            // }
          } else {
            // that.$toast(result.msg);
            //that.$toast(1111);
            that.msg = '/api/wechat/auth:' + result.msg;
            //this.showQYWXCode();
          }
        });
      } else {
        //
        this.getWxOauth2();
      }
    }
  },
  mounted: function () {
    this.host = localStorage.getItem('host');
    // alert( window.location.href);
    localStorage.setItem('appurl', window.location.href);
    this.query = JSON.parse(JSON.stringify(this.$route.query));
    // this.query.aid = 1;
    // this.query.spread = 13;
    if (this.query.aid) {
      localStorage.setItem('query', JSON.stringify(this.query));
    }
    this.query = JSON.parse(localStorage.getItem('query'));
    this.setInTime = setInterval(() => {
      this.text2 += '.';
      if (this.text2.length > 6) {
        this.text2 = '';
      }
    }, 300);
    this.auth();
  }
};